<template>
  <v-card class="qtm-border" variant="flat">
    <v-card-title>
      <div class="d-flex justify-space-between">
        <div>
          QTM Notes
        </div>
        <qtm-icon-btn color="secondary" icon="mdi-pencil" size="small" @click="edit = !edit" />
      </div>
    </v-card-title>
    <v-card-text v-if="edit">
      <qtm-textarea v-model="notes" />
      <qtm-btn :loading="loading" secondary @click="saveNote">
        Save
      </qtm-btn>
    </v-card-text>
    <v-card-text v-else>
      <div v-if="order.agent_notes?.user" class="d-flex justify-space-between mb-2">
        <div class="font-weight-bold" v-text="order.agent_notes.user" />
        <div v-text="dateTime(order.agent_notes.date)" />
      </div>
      <div class="show-newlines" v-text="order.agent_notes?.notes" />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { DateTime, type Order } from '@quotetome/materials-api'
import { dateTime } from '~/models/filters'

const props = defineProps<{
  order: Order
}>()

const edit = ref(!props.order.agent_notes?.notes)
const loading = ref(false)
const notes = ref(props.order.agent_notes?.notes ?? '')

const adminStore = useAdminStore()
const authStore = useAuthStore()
const user = authStore.user
const { $api, $error, $toast } = useNuxtApp()
const saveNote = async () => {
  const orderData = {
    id: props.order.id,
    agent_notes: {
      date: new DateTime().unix(),
      notes: notes.value,
      user: `${user!.first_name} ${user!.last_name}`,
    }
  }

  loading.value = true
  try {
    const updatedOrder = await $api.v1.rfqs.update(orderData as any)
    adminStore.updateOrder(updatedOrder)
    $toast.success('Note updated')
    edit.value = false
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}
</script>
