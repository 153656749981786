<template>
  <div>
    <div v-if="isEmailReady">
      <qtm-icon-btn
        class="send-email-btn"
        color="white"
        icon="mdi-email"
        @click="showDialog()"
      />
      <send-email
        :order="order"
        :replied-email="inReplyTo"
        :reply-type="replyType"
        :show="dialog"
        @close="hideDialog"
      />
      <v-expansion-panels
        v-model="openEmail"
        active-class="row-email-active"
        class="email-grid"
      >
        <v-expansion-panel disabled readonly>
          <v-expansion-panel-title>
            <v-row class="qtm-label" no-gutters>
              <v-col cols="3">
                {{ isInbound ? 'From' : 'To' }}
              </v-col>
              <v-col cols="7">
                Subject
              </v-col>
              <v-col class="text-right" cols="2">
                Date
              </v-col>
            </v-row>
          </v-expansion-panel-title>
        </v-expansion-panel>
        <v-expansion-panel
          v-for="email in emails"
          :key="email.id"
          :class="type"
          :value="email.id"
          @click="openEmailDetail(email.id)"
        >
          <v-expansion-panel-title>
            <v-row class="rows qtm-body-small" :class="email.tracking_status" no-gutters>
              <v-col class="hide-overflow" cols="3">
                <v-icon
                  v-if="canShowAlertIcon(email)"
                  color="purple"
                  size="small"
                >
                  mdi-alert
                </v-icon>
                <span>
                  {{ isInbound?email.from_address:email.to_addresses }}
                </span>
              </v-col>
              <v-col class="hide-overflow" cols="7">
                {{ email.subject }}
              </v-col>
              <v-col class="text-right text-mid-grey" cols="2">
                <v-icon v-if="email.attachment_count" size="small">
                  mdi-paperclip
                </v-icon>
                {{ dateCreated(email) }}
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="email-detail">
            <view-email-detail
              :can-create-invoice="order.state.can_create_invoice"
              :email-content="emailContent"
              :order="order"
              @create-invoice="prepCreateInvoice"
              @refresh="fetchEmails"
              @reply="showReplyDialog"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else>
      <v-alert class="ml-auto mr-auto mt-15 elevation-10" max-width="45rem" type="info">
        This company is not email ready, please set up the company first.
      </v-alert>
    </div>
    <invoice-create-dialog v-model="showCreateInvoice" :email="createInvoiceEmail" :order="order" />
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { timeOrDateTime } from '~/models/filters'
import InvoiceCreateDialog from '@/components/invoices/invoice-create-dialog.vue'
import ViewEmailDetail from '@/components/admin/emails/view-email-detail.vue'
import SendEmail from '@/components/admin/emails/send-email.vue'

const EMAIL_REFRESH_INTERVAL = 10 * 1000 // 10 seconds

export default {
  name: 'view-emails',
  components: { InvoiceCreateDialog, ViewEmailDetail, SendEmail },
  props: {
    initialEmail: {
      type: Number,
      default: undefined,
    },
    order: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ['update:initial-email'],
  data() {
    return {
      createInvoiceEmail: undefined,
      emails: [],
      loading: true,
      emailContent: {},
      dialog: false,
      inReplyTo: null,
      openEmail: null,
      replyType: 'single',
      refreshInterval: -1,
      showCreateInvoice: false,
    }
  },
  computed: {
    isEmailReady() {
      return this.order.owner.company.email_ready
    },
    isInbound() {
      return this.type === 'inbound'
    },
  },
  watch: {
    visible(isVisible) {
      if (isVisible) { this.startEmailRefresh() }
      else { this.stopEmailRefresh() }
    },
  },
  mounted() {
    this.startEmailRefresh()
  },
  beforeUnmount() {
    this.stopEmailRefresh()
  },
  methods: {
    ...mapActions(useAdminStore, ['removeUnreadEmail', 'removeBouncedEmail']),
    dateCreated(email) {
      return timeOrDateTime(email.date_created)
    },
    openEmailDetail(emailId) {
      this.fetchEmailContent(emailId)
    },
    showReplyDialog(email, type) {
      this.inReplyTo = email
      this.replyType = type
      this.dialog = true
    },
    showDialog() {
      this.inReplyTo = null
      this.dialog = true
    },
    hideDialog() {
      this.inReplyTo = null
      this.dialog = false
    },
    markAsRead(emailId) {
      try {
        this.$api.v1.notifications.email.patch(emailId, { tracking_status: 'opened' })
        this.$api.v1.notifications.email.markAsRead(emailId)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    async fetchEmailContent(emailId) {
      this.loading = true
      try {
        this.emailContent = await this.$api.v1.notifications.email.detail(emailId)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async fetchEmails() {
      this.loading = true
      try {
        this.emails = await this.$api.v1.notifications.email.listByRfq(this.order, { emailType: this.type })

        if (this.initialEmail) {
          this.openEmail = this.initialEmail
          this.openEmailDetail(this.initialEmail)
          this.$emit('update:initial-email', null)
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    startEmailRefresh() {
      this.stopEmailRefresh()
      this.fetchEmails()
      this.refreshInterval = setInterval(this.fetchEmails, EMAIL_REFRESH_INTERVAL)
    },
    stopEmailRefresh() {
      clearInterval(this.refreshInterval)
    },
    canShowAlertIcon(email) {
      return ['bounced', 'rejected'].includes(email.tracking_status)
    },
    prepCreateInvoice(email) {
      this.createInvoiceEmail = email
      this.showCreateInvoice = true
    },
  }
}
</script>
<style scoped>
  .rows {
    overflow: hidden;
  }
  .inbound .rows.delivered, .outbound .rows.rejected, .outbound .rows.bounced {
    font-weight: bold;
  }
  .hide-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .row-email-active button {
    background-color: rgb(var(--v-theme-background));
    padding: 0 10px;
  }
  .send-email-btn {
    position: absolute;
    right:  5px;
    top: 5px;
    z-index: 2;
    background-color: rgb(var(--v-theme-primary));;
  }
</style>
